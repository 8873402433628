var __tnt = window.__tnt || {};
    __tnt.ads = window.__tnt.ads || {};
    __tnt.ads.dfp = window.__tnt.ads.dfp || {};

var googletag = window.googletag || {};
    googletag.cmd = window.googletag.cmd || [];

var gptAdSlots = window.gptAdSlots || [];

(function(w, d, g, __tnt, slots) {

    var me = __tnt.ads.dfp || (__tnt.ads.dfp = {});

    me.func = {

        createAdTag: function(id, refreshable, optClasses=[]) {
            let df = d.createDocumentFragment(),
                container = d.createElement('div'),
                ad = d.createElement('div');

            container.classList.add('tnt-ads-container', 'text-center');
            ad.id = id;
            ad.classList.add('tnt-ads', 'dfp-ad');
            if (refreshable) ad.setAttribute('data-refresh', 'true');
            if (optClasses.length > 0) {
                for (let i = 0; i < optClasses.length; i++) {
                    ad.classList.add(optClasses[i]);
                }
            }

            df.appendChild(container);
            df.querySelector('.tnt-ads-container').appendChild(ad);
            return df;
        },

        defineSlot: function(id, adUnit, sizes) {
            let mapping;
            g.cmd.push(function() {
                if (sizes.allViewports) {
                    mapping = g.sizeMapping()
                        .addSize([0, 0], sizes.allViewports)
                        .build();
                } else {
                    mapping = g.sizeMapping()
                        .addSize([320, 0], sizes.viewportSizes.xs)
                        .addSize([768, 0], sizes.viewportSizes.sm)
                        .addSize([992, 0], sizes.viewportSizes.md)
                        .addSize([1200, 0], sizes.viewportSizes.lg)
                        .build();
                }
                slots[id] = g.defineSlot(adUnit, sizes.slotSize, id)
                             .defineSizeMapping(mapping)
                             .setCollapseEmptyDiv(true)
                             .addService(g.pubads());
                g.pubads().enableLazyLoad({
                    fetchMarginPercent: 500,  // Fetch slots within 5 viewports.
                    renderMarginPercent: 200, // Render slots within 2 viewports.
                    mobileScaling: 2.0        // Double the above values on mobile.
                });
            });
        },

        fetchAd: function(id) {
            g.cmd.push(function(){
                g.pubads().refresh([slots[id]]);
            });
        }
    };
})(window, document, googletag, __tnt, gptAdSlots);